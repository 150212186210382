import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/RecklessNeue-Regular.woff2\",\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"recklessNeue\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-sans\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGroteskHeadings\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/components/concierge/Concierge.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/components/cssVariables/CssVariables.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/components/envProvider/EnvProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/components/muiTheme/MuiTheme.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/lib/src/components/searchHighlighterProvider/SearchHighlighterProvider.tsx");
